<template>
  <div class="feinongWap">
    <div v-if="!isApp" class="tabbar" :class="navBarFixed ? 'navBarFixed' : ''">
      <img @click="goback" src="../../../../static/common/logo.png" alt="" />
    </div>
    <div class="top">
      <img @click="openLogin" src="/subject/feinong/wap/topButton.png" alt="" />
    </div>
    <div class="second">
      <img class="tit" src="/subject/feinong/wap/secondTit.png" alt="" />
      <p>美元现金1手即领，所有用户均可领取</p>
      <span class="detial" @click="checkActive(true)">活动详情</span>
      <div class="secondBox">
        <ul>
          <li>
            <img @click="openLogin" src="/subject/feinong/wap/ling.png" alt="" />
          </li>
          <li>
            <img @click="openLogin" src="/subject/feinong/wap/ling.png" alt="" />
          </li>
        </ul>
        <ul class="ulOther">
          <li>
            <img @click="openLogin" src="/subject/feinong/wap/ling.png" alt="" />
          </li>
          <li>
            <img @click="openLogin" src="/subject/feinong/wap/ling.png" alt="" />
          </li>
          <li>
            <img @click="openLogin" src="/subject/feinong/wap/ling.png" alt="" />
          </li>
        </ul>
      </div>
      <p class="pTimer">
        非农活动倒计时：<span>{{ day }}天{{ hour }}:{{ min }}:{{ second }}</span>
      </p>
      <div class="btnBox">
        <img @click="openLive" src="/subject/feinong/wap/zxkf.png" alt="" />
        <img @click="openLogin" src="/subject/feinong/wap/ljlj.png" alt="" />
      </div>
      <p>机会有限，先到先得</p>
    </div>
    <div class="third">
      <img class="tit" src="/subject/feinong/wap/thirdTit.png" alt="" />
      <div class="thirdBox">
        <ul>
          <li>
            <span></span>
            非农就业数据发布时间：每月第一个星期五，北京时间晚20:30（11月-3月冬令时21:30）；
          </li>
          <li>
            <span></span>
            非农就业数据，反映的是美国非农业人口就业状况的数据，客观的反应了美国经济走势，影响美元价格波动；
          </li>
          <li>
            <span></span>
            因美元价格与伦敦金价格的相关性，每次非农数据发布前后，都会引发伦敦金/银市场行情大幅波动，是伦敦金/银买卖的大好时机；
          </li>
          <li>一 非农数据数值增加<i></i>美国经济向好<i></i>利好美元<i></i>利空金银；</li>
          <li>一 非农数据数值减少<i></i>美国经济走低<i></i>利空美元<i></i>利好金银；</li>
        </ul>
        <div @mouseleave="tabLeave()">
          <p @mouseover="hoverli(0)" :class="control === 1 ? 'active1' : 'noactive1'"></p>
          <p @mouseover="hoverli(1)" :class="control === 0 ? 'active2' : 'noactive2'"></p>
        </div>
        <div class="echartsBox" @mouseleave="tabLeave()">
          <div id="echartsBox_one" @mouseover="hoverli(0)" v-show="control == 0" class="echartsBoxItem"></div>
          <div id="echartsBox_two" @mouseover="hoverli(1)" v-show="control == 1" class="echartsBoxItem"></div>
        </div>
        <!-- <dl :class="control === 1 ? 'dlImg' : 'dlImgOther' "></dl> -->
      </div>
    </div>
    <div class="fourth">
      <img class="tit" src="/subject/feinong/wap/fourthTit.png" alt="" />
      <div class="fourthKK">
         <h3><i></i>经济数据 (公布时间8/4 20:30)</h3>
        <table>
          <tr>
            <th></th>
            <th>前值</th>
            <th>预测值</th>
          </tr>
          <tr>
            <th>美国7月失业率</th>
            <th>3.6%</th>
            <th>3.6%</th>
          </tr>
          <tr>
            <th>美国7月季调后非农就业人口</th>
            <th>20.9万人</th>
            <th>18万人</th>
          </tr>
        </table>

        <h3><i></i>伦敦金操作建议</h3>
        <img src="/subject/feinong/part3M2.png" class="part3M2" alt="" />
        <p>
          <b>压力</b>：1987美元/盎司<br />
          <b>支撑</b>：1965/1951/1929 美元/盎司<br />
          <b>交易研判</b>：<br />
          此前美联储一如预期宣布加息，投资者消化主席鲍威尔的发言。此次加息是美联储在过去12次会议中的第11次加息，指标隔夜利率设定在5.25%-5.50%的区间。受助于美元和美债收益率下跌，金价延续涨势。从日线级别观察，留意38.2%回调位1951美元/盎司防守状况伺机介入多单。跌破1929美元/盎司再转为偏空看待交易。
        </p>
      </div>
      <img class="fourthButton" @click="openLogin" src="/subject/feinong/wap/topButton.png" alt="" />
    </div>
    <div class="fifth">
      <img class="tit" src="/subject/feinong/wap/fifthTit.png" alt="" />
      <div class="phoneBox">
        <p>
          <img src="/subject/feinong/wap/1.png" alt="" />
          <img src="/subject/feinong/wap/2.png" alt="" />
          <img src="/subject/feinong/wap/3.png" alt="" />
          <img src="/subject/feinong/wap/4.png" alt="" />
        </p>
        <img class="phone" src="/subject/feinong/wap/phone.png" alt="" />
      </div>
      <div class="codeBox">
        <img src="/subject/feinong/wap/codeboxLeft.png" alt="" />
        <img @click="openLive" src="/subject/feinong/wap/codeboxRight.png" alt="" />
      </div>
      <p class="fifthTxt">红狮专注伦敦金/银15年，只为给您这1秒的交易，<br />带来更专业的体验</p>
    </div>
    <contactusVue v-if="!isApp"></contactusVue>
    <div v-if="showDialog" class="dialog">
      <img class="closer" @click="checkActive(false)" src="/subject/feinong/wap/guanbi2.png" alt="" />
      <ul class="dialogContent">
        <li>
          <span>活动对象：</span>
          本公司所有新老用户
        </li>
        <li>
          <span>活动时间：</span>
          {{ activityTime.FNStartTime }}—{{ activityTime.FNEndTime }}
        </li>
        <li>
          <span>兑奖截止时间：</span>
          {{ activityTime.FNExchangeEndTime }}（逾期失效）
        </li>
        <li>
          <span>活动规则：</span><br>
          活动期间内，凡在本公司的APP或MT4交易平台交易伦敦金、伦敦银等指定品种，完成指定交易手数，即可兑换相应美元现金奖励，交易时间结束后，统一开始兑换，限兑奖一次； 
        </li>
        <li>
          <span>交易奖励：</span><br />
          <table>
            <tr>
              <th>赠送条件</th>
              <th>美元现金奖励</th>
            </tr>
            <tr>
              <th>平仓≥1手</th>
              <th>2美元</th>
            </tr>
            <tr>
              <th>平仓≥2手</th>
              <th>5美元</th>
            </tr>
            <tr>
              <th>平仓≥5手</th>
              <th>13美元</th>
            </tr>
            <tr>
              <th>平仓≥8手</th>
              <th>22美元</th>
            </tr>
            <tr>
              <th>平仓≥15手</th>
              <th>44美元</th>
            </tr>
            <tr>
              <th>平仓≥25手</th>
              <th>75美元</th>
            </tr>
            <tr>
              <th>平仓≥49手</th>
              <th>152美元</th>
            </tr>
            <tr>
              <th>平仓≥68手</th>
              <th>218美元</th>
            </tr>
            <tr>
              <th>平仓≥138手</th>
              <th>455美元</th>
            </tr>
            <tr>
              <th>平仓≥258手</th>
              <th>877美元</th>
            </tr>
            <tr>
              <th>平仓≥388手</th>
              <th>1358美元</th>
            </tr>
            <tr>
              <th>平仓≥488手</th>
              <th>1756美元</th>
            </tr>
            <tr>
              <th>平仓≥588手</th>
              <th>2176美元</th>
            </tr>
            <tr>
              <th>平仓≥688手</th>
              <th>2615美元</th>
            </tr>
            <tr>
              <th>平仓≥888手</th>
              <th>3463美元</th>
            </tr>
          </table>
        </li>
        <li>
          <span>领取方式：</span><br />
          1.非农奖励在“APP”→“我的”→“活动中心”→“非农活动”中领取；<br />
          2.活动只计算非农周的交易手数，奖励会在非农交易周之后开启兑换；<br />
          3.奖励后的美元可直接提现，亦可继续用于交易；<br />
          4.美元现金奖励需在兑奖截止日期前联系客服领取，逾期失效；<br />
        </li>
        <li>
          <span>注意事项</span><br>
          1. 客户参与活动需在本公司开立真实交易账户,参与该活动的新用户，为保证资金安全，在初始L1层级，账户强平比例上调至50%，升级后自动恢复为20%；<br>
          2. 本活动指定品种详情可咨询客服，交易手数计算以开平仓为准；<br>
          3. 各账户层级用户均可参加本活动，美元现金奖励将依据活动交易期间的最终开平仓手数发放；<br>
          4. 凡推广商及其名下客户不参与本活动；<br>
          5. 本公司保留随时修订、暂停、终止本活动及任何相关规则条款之权利及其解释权。<br>
        </li>
      </ul>
    </div>
    <div class="dark" v-if="showDialog"></div>
  </div>
</template>

<script>
import ContactusVue from '../../Contactus.vue'
import { getFeinongTime } from '../../../../api/info'
import { getSYLApi, getFNApi } from '../../../../api/subject/feinong'
export default {
  name: 'feinongWap',
  components: {
    ContactusVue,
  },
  data() {
    return {
      curStartTime: '2023-08-05 06:00:00',
      day: '07',
      hour: '00',
      min: '00',
      second: '00',
      control: 0,
      timer: Function,
      showDialog: false,
      navBarFixed: false,
      scroll: '',
      isApp: false,
      activityTime: {
        FNStartTime: null,
        FNEndTime: null,
        FNExchangeEndTime: null,
      },
    }
  },
  created() {
    this.$emit('controlShow', true)
    this.$router.push({
        name:'Index',
      })
    // 判断是从A前还是A后跳转过来
    if (!sessionStorage.getItem('craig')) {
      if (!this.$route.query.craig) {
        sessionStorage.setItem('craig', window.location.origin)
      } else {
        sessionStorage.setItem('craig', this.$route.query.craig)
      }
    }
    this.timer = setInterval(this.updateDiv, 3000)
    if (this.$route.query.isApp) {
      this.isApp = true
    }
    // 获取 活动 时间
    getFeinongTime().then((res) => {
      this.activityTime = res.data
    })
    // 美国失业率报告
    getSYLApi().then((res) => {
      var arr_X = []
      var arr_Y = []
      var arr = [res.data[0], res.data[1], res.data[2], res.data[3], res.data[4], res.data[5]]
      for (let i = arr.length - 1; i >= 0; i--) {
        arr_X.push(arr[i].Date.slice(0, 10))
        arr_Y.push(arr[i].Announcement)
      }
      let myChart = this.$echarts.init(document.getElementById('echartsBox_one'))
      let option = {
        legend: {
          textStyle: { fontSize: 16 },
        },
        tooltip: {
          trigger: 'axis',
          triggerOn: 'mousemove',
          enterable: true, //鼠标是否可进入提示框浮层中
          formatter: this.formatterHover, //修改鼠标悬停显示的内容
        },
        dataZoom: [
          {
            type: 'inside',
            xAxisIndex: 0,
            zoomLock: true,
            moveOnMouseMove: true,
            preventDefaultMouseMove: false,
            zoomOnMouseWheel: false,
            startValue: 1, //数据窗口范围的起始数值
            endValue: 4,
          },
        ],
        grid: {
          // 距离容器上边界40像素
          bottom: '10%', // 下边界距离
          right: '10%', // 右边界距离
          left: '15%', // 左边界距离
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: arr_X,
          axisLabel: {
            interval: 0,
          },
        },
        yAxis: {
          type: 'value',
          name: '今值(%)',
        },
        series: [
          {
            data: arr_Y,
            type: 'line',
            smooth: true,
            showSymbol: false,
            lineStyle: {
              color: '#BFD6FB',
            },
            areaStyle: {
              normal: {
                color: '#e9f1fe',
              },
            },
          },
        ],
      }
      myChart.setOption(option)
    })
    // 美国非农就业
    getFNApi().then((res) => {
      var arr_X = []
      var arr_Y = []
      var arr = [res.data[0], res.data[1], res.data[2], res.data[3], res.data[4], res.data[5]]
      for (let i = arr.length - 1; i >= 0; i--) {
        arr_X.push(arr[i].Date.slice(0, 10))
        arr_Y.push(arr[i].Announcement)
      }
      let myChart_two = this.$echarts.init(document.getElementById('echartsBox_two'))
      let option_two = {
        legend: {
          textStyle: { fontSize: 16 },
        },
        tooltip: {
          trigger: 'axis',
          triggerOn: 'mousemove',
          enterable: true, //鼠标是否可进入提示框浮层中
          formatter: this.formatterHover, //修改鼠标悬停显示的内容
        },
        dataZoom: [
          {
            type: 'inside',
            xAxisIndex: 0,
            zoomLock: true,
            moveOnMouseMove: true,
            preventDefaultMouseMove: false,
            zoomOnMouseWheel: false,
            startValue: 1, //数据窗口范围的起始数值
            endValue: 4,
          },
        ],
        grid: {
          // 距离容器上边界40像素
          bottom: '10%', // 下边界距离
          right: '10%', // 右边界距离
          left: '15%', // 左边界距离
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: arr_X,
          axisLabel: {
            interval: 0,
          },
        },
        yAxis: {
          type: 'value',
          name: '今值(万人)',
        },
        series: [
          {
            data: arr_Y,
            type: 'line',
            smooth: true,
            showSymbol: false,
            lineStyle: {
              color: '#BFD6FB',
            },
            areaStyle: {
              normal: {
                color: '#e9f1fe',
              },
            },
          },
        ],
      }
      myChart_two.setOption(option_two)
    })
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    this.countTime()
  },
  methods: {
    formatterHover(params) {
      return (
        '<span style="padding-left:5px;height:30px;line-height:30px;display: inline-block;">' +
        params[0].axisValue +
        '<br>今值: ' +
        params[0].data +
        '</span>'
      )
    },
    // 返回上级页面
    goback() {
      window.location.href = sessionStorage.getItem('craig')
    },
    // 控制顶部的定位
    handleScroll() {
      this.scroll = document.documentElement.scrollTop + document.body.scrollTop //获取滚动后的高度
      if (this.scroll > 90) {
        this.navBarFixed = true
      } else {
        this.navBarFixed = false
      }
    },
    countTime() {
      // 获取当前时间
      let date = new Date()
      let now = date.getTime()
      // 设置截止时间
      let endDate = new Date(this.curStartTime) // this.curStartTime需要倒计时的日期
      let end = endDate.getTime()
      // 时间差
      let leftTime = end - now
      // 定义变量 d,h,m,s保存倒计时的时间
      if (leftTime >= 0) {
        // 天
        let day = Math.floor(leftTime / 1000 / 60 / 60 / 24)
        // 三元表达式判断不满10前面加0
        this.day = day < 10 ? '0' + day : day
        // 时
        let h = Math.floor((leftTime / 1000 / 60 / 60) % 24)
        this.hour = h < 10 ? '0' + h : h
        // 分
        let m = Math.floor((leftTime / 1000 / 60) % 60)
        this.min = m < 10 ? '0' + m : m
        // 秒
        let s = Math.floor((leftTime / 1000) % 60)
        this.second = s < 10 ? '0' + s : s
      } else {
        this.day = '00'
        this.hour = '00'
        this.min = '00'
        this.second = '00'
      }
      // 等于0的时候不调用
      if (Number(this.hour) === 0 && Number(this.day) === 0 && Number(this.min) === 0 && Number(this.second) === 0) {
        return
      } else {
        // 递归每秒调用countTime方法，显示动态时间效果,
        setTimeout(this.countTime, 1000)
      }
    },

    // 鼠标进入事件
    hoverli(attr) {
      clearInterval(this.timer)
      this.control = attr
    },
    // tabLeave鼠标离开事件
    tabLeave() {
      this.timer = setInterval(this.updateDiv, 3000)
    },
    // 切换rollBox的方法
    updateDiv() {
      if (this.control > 0) {
        this.control = this.control - 1
      } else {
        this.control = 1
      }
    },
    // 查看活动
    checkActive(attr) {
      this.showDialog = attr
    },
    openLogin() {
      window.open('https://www.rlcproltd.net/uc/login')
    },
    openLive() {
      window.open(
        this.$parent.live800Url
        // 'https://chat32.live800.com/live800/chatClient/chatbox.jsp?companyID=215184&configID=35519&jid=5377035377'
      )
    },
    openDownload() {
      window.open('https://oss.0790jiaxiao.com/HS/software/hs_9999.apk')
    },
  },
}
</script>

<style lang="less" scoped>
.tabbar {
  width: 100%;
  background-color: #ffffff;

  img {
    margin: 10px 30px;
    width: 130px;
  }
}

.navBarFixed {
  position: fixed;
  top: 0;
  z-index: 999;
}

.feinongWap {
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .top {
    width: 100%;
    height: 400px;
    background: url('/subject/feinong/wap/topBanner.png') no-repeat;
    background-size: 100% 100%;
    position: relative;

    img {
      position: absolute;
      top: 47%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 33px;
      width: auto;
    }
  }

  .second {
    width: 100%;
    height: 419px;
    box-sizing: border-box;
    padding-top: 5px;
    background: url('/subject/feinong/wap/secondBanner.png') no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .tit {
      width: 90%;
      height: auto;
      margin-bottom: 8px;
    }

    p {
      font-size: 14px;
      color: #c61726;
    }

    .detial {
      font-size: 14px;
      color: #c61726;
      text-decoration: underline;
      margin: 3px auto 8px;
    }

    .secondBox {
      width: 98%;
      height: 224px;
      background: url('/subject/feinong/wap/secondKK.png') no-repeat;
      background-size: 100% 100%;
      box-sizing: border-box;
      padding: 30px 0;
      margin-bottom: 8px;

      ul {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        li {
          width: 100px;
          height: 86px;
          background: url('/subject/feinong/wap/secondImg1.png') no-repeat;
          background-size: 100% 100%;
          margin: 0 3px;
          position: relative;

          img {
            width: 48px;
            height: auto;
            position: absolute;
            bottom: 7px;
            left: 50%;
            transform: translate(-50%, 0);
          }
        }

        li:nth-child(2) {
          background: url('/subject/feinong/wap/secondImg2.png') no-repeat;
          background-size: 100% 100%;
        }
      }

      .ulOther {
        margin-top: 5px;

        li:nth-child(1) {
          background: url('/subject/feinong/wap/secondImg3.png') no-repeat;
          background-size: 100% 100%;
        }

        li:nth-child(2) {
          background: url('/subject/feinong/wap/secondImg4.png') no-repeat;
          background-size: 100% 100%;
        }

        li:nth-child(3) {
          background: url('/subject/feinong/wap/secondImg5.png') no-repeat;
          background-size: 100% 100%;
        }
      }
    }

    .btnBox {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 104px;
        height: auto;
        margin: 8px 12px;
      }
    }
  }

  .third {
    width: 100%;
    height: 700px;
    background: url('/subject/feinong/wap/thirdBackground.png') no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    padding-top: 8px;

    .tit {
      width: 85%;
      height: auto;
      margin-bottom: 10px;
    }

    .thirdBox {
      width: 361px;
      height: 700px;
      background: url('/subject/feinong/wap/thirdKK.png') no-repeat;
      background-size: 100% 100%;

      ul {
        box-sizing: border-box;
        padding: 10px 13px 10px 15px;

        li {
          font-size: 14px;
          color: #c61726;
          line-height: 25px;
          margin-bottom: 3px;

          span {
            display: inline-block;
            width: 7px;
            height: 7px;
            background-color: #c61726;
            border-radius: 50%;
            margin-right: 5px;
          }

          i {
            width: 13px;
            height: 13.5px;
            display: inline-block;
            background: url('/subject/feinong/icon_l.png') no-repeat;
            background-size: 100% 100%;
            margin: 0 7px;
          }
        }
      }

      div {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          width: 167.5px;
          height: 55px;
        }

        .noactive1 {
          background: url('/subject/feinong/wap/roll3.png') no-repeat;
          background-size: 100% 100%;
        }

        .noactive2 {
          background: url('/subject/feinong/wap/roll1.png') no-repeat;
          background-size: 100% 100%;
        }

        .active1 {
          background: url('/subject/feinong/wap/roll4.png') no-repeat;
          background-size: 100% 100%;
        }

        .active2 {
          background: url('/subject/feinong/wap/roll2.png') no-repeat;
          background-size: 100% 100%;
        }
      }

      .echartsBox {
        width: 335px;
        height: 200px;
        background-color: #fff;
        margin: 0 auto;

        .echartsBoxItem {
          width: 330px;
          height: 200px;
          margin: 0 auto;
        }
      }
    }
  }

  .fourth {
    width: 100%;
    height: 900px;
    background: url('/subject/feinong/wap/fourthBackground.png') no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    padding-top: 10px;

    .tit {
      width: 85%;
      height: auto;
      margin-bottom: 10px;
    }

    .fourthKK {
      display: block;
      margin: 0 auto;
      width: 97%;
      height: auto;
      background: url('/subject/feinong/wap/fourthkkBackground.png') no-repeat;
      background-size: 100% 100%;
      padding-top: 5px;
      box-sizing: border-box;
      padding-left: 20px;
      padding-bottom: 30px;
      margin-bottom: 10px;

      h3 {
        font-size: 18px;
        color: #c9241e;
        margin: 10px 0;

        i {
          display: inline-block;
          width: 3px;
          height: 13px;
          margin-right: 10px;
          background: #ef6655;
          border-radius: 5px;
        }
      }

      table {
        width: 95%;
        border: 1px solid #e28172;
        border-radius: 10px;
        background-color: #fffbee;

        tr {
          th {
            text-align: center;
            font-weight: 500;
            height: 40px;
            border-bottom: 1px solid #c4693b;
            border-right: 1px solid #c4693b;
            padding: 5px;
            color: #c4693b;
            font-size: 14px;
          }

          th:last-child {
            border-right: 1px solid transparent;
          }
        }

        tr:nth-child(1) {
          th {
            width: 37%;
            font-size: 16px;
            font-weight: 600;
            color: #cd7f4e;
          }
        }

        tr:last-child {
          th {
            border-bottom: none;
          }
        }
      }

      .part3M2 {
        width: 95%;
        margin-bottom: 10px;
        border-radius: 10px;
      }

      p {
        width: 95%;
        font-size: 14px;
        color: #c9241e;
        line-height: 24px;
        text-align: justify;
      }

      .sp {
        font-size: 12px;
        opacity: 0.5;
        line-height: 20px;
      }
    }

    .fourthButton {
      height: 33px;
      width: auto;
    }
  }

  .fifth {
    width: 100%;
    height: 614px;
    background: url('/subject/feinong/wap/fifthBackground.png') no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    padding-top: 10px;

    .tit {
      width: 85%;
      height: auto;
    }

    .phoneBox {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px auto;

      p {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 30px;

        img {
          width: 138px;
          height: auto;
          margin: 15px auto;
        }
      }

      .phone {
        width: 158px;
        height: auto;
      }
    }

    .codeBox {
      display: flex;
      justify-content: center;
      align-items: center;

      img:nth-child(1) {
        width: 143px;
        margin-right: 20px;
      }

      img:nth-child(2) {
        width: 156px;
      }
    }

    .fifthTxt {
      color: #c31828;
      text-align: center;
      font-size: 14px;
      line-height: 24px;
      margin-top: 15px;
    }
  }

  .dialog {
    position: relative;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    height: 480px;
    box-shadow: 0px 0px 95px 0px rgba(187, 188, 192, 0.1);
    background-color: #fdf5e6;
    border-radius: 15px;
    overflow-y: scroll;
    z-index: 99999999;

    .closer {
      width: 23px;
      height: auto;
      position: absolute;
      top: 5px;
      right: 5px;
    }

    .dialogContent {
      box-sizing: border-box;
      padding: 10px;
      overflow: hidden;

      li {
        font-size: 14px;
        color: #a86e4f;
        line-height: 24px;

        span {
          font-weight: 600;
          color: #e34a3f;
        }

        img {
          width: 100%;
          height: auto;
          margin: 20px 0;
        }

        table {
          width: 100%;
          border: 1px solid #e34a3f;
          border-radius: 10px;

          tr {
            th {
              text-align: center;
              font-weight: 500;
              line-height: 24px;
              border-bottom: 1px solid #d2a765;
              border-right: 1px solid #d2a765;
              padding: 5px;
            }

            th:last-child {
              border-right: 1px solid transparent;
            }
          }

          tr:nth-child(1) {
            th {
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  .dark {
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 101;
  }
}

@media screen and (min-width: 400px) {
  .feinongWap {
    .fourth {
      // height: 800px;
    }
  }
}
</style>
